.background {
	position: absolute;
	width: 100%;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	z-index: -1;
	top: 0;
	left: 0;
	margin: 0;
	&_item {
		list-style: none;
		position: absolute;
		width: 5rem;
		height: 5rem;
		bottom: -9rem;
		background-color: $secondaryColor;
		opacity: 0.5;
		display: block;
		animation: animate 10s linear infinite;
		&_1 {
			left: 10%;
			width: 5rem;
			height: 5rem;
			animation-delay: 0s;
			animation-duration: 15s;
		}
		&_2 {
			left: 20%;
			width: 12rem;
			height: 12rem;
			animation-delay: 2s;
			animation-duration: 5s;
		}
		&_3 {
			left: 46%;
			width: 8rem;
			height: 8rem;
			animation-delay: 3s;
			animation-duration: 10s;
		}
		&_4 {
			left: 62%;
			width: 10rem;
			height: 10rem;
			animation-delay: 1s;
			animation-duration: 5s;
		}
		&_5 {
			left: 78%;
			width: 2rem;
			height: 2rem;
			animation-delay: 2s;
			animation-duration: 10s;
		}
		&_6 {
			left: 94%;
			width: 8rem;
			height: 8rem;
			animation-delay: 4s;
		}
		&_7 {
			left: 2%;
			width: 4rem;
			height: 4rem;
			animation-delay: 1s;
			animation-duration: 10s;
		}
		&_8 {
			left: 50%;
			width: 10rem;
			height: 10rem;
			animation-delay: 7s;
			animation-duration: 15s;
		}
		&_9 {
			left: 20%;
			width: 2rem;
			height: 2rem;
			animation-delay: 1s;
		}
		&_10 {
			left: 88%;
			width: 8rem;
			height: 8rem;
			animation-delay: 2s;
		}
	}
}

@keyframes animate {
	0% {
		transform: translateY(5rem) rotate(0deg) scale(1);
		opacity: 1;
	}
	100% {
		transform: translateY(-100vh) rotate(360deg) scale(0.6);
		opacity: 0.2;
	}
}
