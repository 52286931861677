.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: background 0.2s;
  background: rgba($color: $outlineColor, $alpha: 0.8);
  &_ligthTheme {
    background: none;
  }
  &_group {
    display: flex;
    flex-grow: 1;
    @media (max-width: $bp-medium) {
      flex-direction: column;
    }
    &_left {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 50%;
      min-width: 50%;
      padding-left: 10rem;
      @media (max-width: $bp-medium) {
        padding: 0 2rem;
        text-align: center;
        min-width: 100%;
        max-width: 100%;
        flex-grow: 0;
      }
    }
    &_right {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 8rem;
      flex-basis: 50%;
      @media (max-width: $bp-medium) {
        align-items: flex-start;
        padding: 1rem 4rem;
        flex-grow: 1;
      }
    }
  }
}

.capture {
  font-size: 6rem;
  letter-spacing: 1.2rem;
  width: 100%;
  @media (max-width: $bp-small) {
    text-align: center;
    font-size: 5rem;
  }
}
.heroText {
  text-transform: uppercase;
  color: $secondaryColor;
  z-index: 2;
}

.steps {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 3rem;
  // color: $lightColor;
  color: $primaryColor;
  &_group {
    display: flex;
    margin-bottom: 2rem;
    & i {
      color: $primaryColor;

      font-size: 4rem;
    }
  }
  &_item {
    list-style: none;
    margin-left: 2rem;
  }
  @media (max-width: $bp-medium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
