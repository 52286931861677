.footer {
	width: 100%;
	height: 6rem;
	background: $primaryColor;
	color: $lightColor;
	display: flex;
	align-items: center;
	padding: 0 2rem;
	justify-content: center;
	transition: $transition;
	font-size: 1.8rem;

	&_imgGroup {
		display: flex;
	}
	&_img {
		width: 3rem;
		height: 3rem;
		margin-right: 2rem;
		transition: $transition;
		&:hover {
			transform: scale(1.1);
		}
	}
}

.footer_lightTheme {
	background: $lightModeColor;
	color: $primaryColor;
}
