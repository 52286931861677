// Color preferences
$primaryColor: #2f2d2e;
$secondaryColor: #da2c38;
$shadowColor: #747572;
$lightColor: #fffffc;
$outlineColor: #8b94a3;
$lightModeColor: #ffd4c1;

// Breakpoints for media queries
// 1em = 16px
$bp-largest: 100em; // 1630px
$bp-large: 85em; // 1300px
$bp-medium: 56em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
// Other

$transition: all 0.2s;
