@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

// root selector for futher rem unit usage (1rem = 10px)
:root {
  font-size: 62.5%;
  user-select: none;
  // for responsive markup - tunning 1 rem value
  @media (max-width: $bp-largest) {
    font-size: 50%;
  }
  @media (max-width: $bp-large) {
    font-size: 45%;
  }
  @media (max-width: $bp-small) {
    font-size: 25%;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.space {
  flex-grow: 1;
}

.underconstruction {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  font-size: 5rem;
  font-weight: bold;
  flex-direction: column;
}
