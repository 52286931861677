.modeToggler {
	position: relative;
	cursor: pointer;
	width: 40px;
	height: 20px;
	-webkit-appearance: none;
	background: $lightModeColor;
	outline: none;
	user-select: none;
	border-radius: 20px;
	box-shadow: inset 0 0 5px rgba($color: #000000, $alpha: 0.2);
	&:checked {
		background: $shadowColor;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		transform: scale(1.1);
		background: $outlineColor;
		top: 0;
		left: 0;
		transition: left 0.5s;
	}
	&:checked:before {
		left: 20px;
		background: $lightColor;
	}
}
